.images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.images .img,
.images .pic {
    flex-basis: 31%;
    margin-bottom: 10px;
    border-radius: 4px;
}
.images .fil {
    flex-basis: 31%;
    margin-bottom: 10px;
    border-radius: 4px;
}

.images .img {
    width: 112px;
    height: 93px;
    background-size: cover;
    margin-right: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.images .fil {
    width: 112px;
    height: 93px;
    background-size: cover;
    margin-right: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.images .img:nth-child(3n) {
    margin-right: 0;
}

.images .fil:nth-child(3n) {
    margin-right: 0;
}

.images .img span {
    display: none;
    text-transform: capitalize;
    z-index: 2;
}

.images .fil span {
    display: none;
    text-transform: capitalize;
    z-index: 2;
}

.images .img::after {
    content: '';
    width: 100%;
    height: 100%;
    transition: opacity .1s ease-in;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
}

.images .img:hover::after {
    display: block;
    background-color: #000;
    opacity: .5;
}

.images .img:hover span {
    display: block;
    color: #fff;
}

.images .fil:hover::after {
    display: block;
    background-color: #000;
    opacity: .5;
}

.images .fil:hover span {
    display: block;
    color: #ac0000;
}

.images .pic {
    background-color: #c8d8cd;
    align-self: center;
    text-align: center;
    padding: 40px 0;
    text-transform: uppercase;
    color: #848EA1;
    font-size: 12px;
    cursor: pointer;
}

.images .fil {
    background-color: #8fffb2;
    align-self: center;
    text-align: center;
    padding: 40px 0;
    text-transform: uppercase;
    color: #2d57a5;
    font-size: 12px;
    cursor: pointer;
}
